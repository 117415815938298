const config = {
  //PROD_API: 'http://3.127.118.13:8080/',
  //DEV_API: 'http://localhost:8080/',
  //MMDEV_API: 'http://18.159.2.102:8080/'
  //DEV_API: "https://nexiondevportal.com:8443/",
  // API: "https://marelli.analytics.nexion.it:8443/",
  API: "https://nexiondevportal.com:8443/",
  API_KEY: "a102d2c1-df6d-411b-a96d-e95f748e5343",
  //API: "https://marelli.analytics.nexion.it:8443/", 
};

export default config;
