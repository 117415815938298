import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
//import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
//import moment from 'moment';
import "chartist/dist/scss/chartist.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "./../../helpers/Constants";
import PulseLoader from "react-spinners/PulseLoader";
//import Select from 'react-select';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Sugar } from "react-preloaders";
import ReactExport from "react-data-export-fix1";
import axios from "axios";

//const { ExportCSVButton } = CSVExport;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const selectRow = {
//   mode: "checkbox",
//   clickToSelect: true,
// };

class DiagnosisCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      visible: false,
      modal_standard: false,
      default_date: new Date(),
      default: false,
      start_date: new Date(),
      end_date: new Date(),
      date: new Date(),
      brands: [],
      family: [],
      chassis: [],
      brand_sel: "",
      family_sel: "",
      chassis_sel: "",
      engine_code_sel: "",
      engine_code: [],
      basic: false,
      req_all: false,
      export_btn: false,
      loading: true,
      tabledataloading: false,
      clearable: true,
    };

    this.tog_standard = this.tog_standard.bind(this);
    i18next.changeLanguage(localStorage.getItem("lang"));
    // DatePicker
    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

  //DatePicker
  handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  handleStart(date) {
    this.setState({ start_date: date });
  }

  handleEnd(date) {
    this.setState({ end_date: date });
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    localStorage.removeItem("DiagnosisSelectedArray");
    var DiagnosisSelectedArray = [];
    localStorage.setItem(
      "DiagnosisSelectedArray",
      JSON.stringify(DiagnosisSelectedArray)
    );
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);
    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org" ||
      authUser.role === "Commercial"
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });

    fetch(config.API + "diagnosis_activation_ecus", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          all_ecus: findresponse,
        });
      });

    fetch(config.API + "diagnosis_brands", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          brands: findresponse,
          loading: false,
        });
      });
  }

  changeBrand = (event) => {
    this.setState({
      brand_sel: brand,
      family: [],
      chassis: [],
      engine_code: [],
      chassis_sel: "",
      family_sel: "",
      engine_code_sel: "",
    });
    var brand = event.target.value;
    fetch(config.API + "get_brand_family/" + brand, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          brand_sel: brand,
          chassis: [],
          engine_code: [],
          chassis_sel: "",
          family_sel: "",
          engine_code_sel: "",
        });
        this.setState({
          family: findresponse,
        });
      });
  };

  changeFamily = (event) => {
    this.setState({
      family_sel: family,
      chassis: [],
      engine_code: [],
      chassis_sel: "",
      engine_code_sel: "",
    });
    var family = event.target.value;
    fetch(config.API + "get_family_chassis/" + encodeURIComponent(family), {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          family_sel: family,
          engine_code: [],
          chassis_sel: "",
          engine_code_sel: "",
        });
        this.setState({
          chassis: findresponse,
        });
      });
  };

  changeChassis = (event) => {
    this.setState({
      chassis_sel: chassis,
      engine_code: [],
      engine_code_sel: "",
    });
    var chassis = event.target.value;
    fetch(config.API + "get_chassis_engine/" + chassis, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          chassis_sel: chassis,
          engine_code: [],
          engine_code_sel: "",
        });
        this.setState({
          engine_code: findresponse,
        });
      });
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    //  console.log(values);
    this.setState({
      products: [],
    });
    if (
      values.brand != "" &&
      values.family != "" &&
      values.chassis != "" &&
      values.engine_code != ""
    ) {
      this.setState({ tabledataloading: true });
      fetch(config.API + "get_diag_communication", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Nexion_API_Key: config.API_KEY,
        },
        body: JSON.stringify(values),
      })
        .then((Response) => Response.json())
        .then((findresponse) => {
          //console.log(findresponse)
          if (findresponse.length <= 0) {
            //alert('No Undecoded DTC Found');
            this.setState({ basic: true });
            this.setState({
              products: [],
              tabledataloading: false,
            });
          } else {
            this.setState({
              products: findresponse,
              tabledataloading: false,
            });
          }
        });
    } else {
      this.setState({ req_all: true });
    }
  }

  handleOnSelect = (row, isSelect) => {
    var selData = JSON.parse(
      localStorage.getItem("DiagnosisSelectedArray") || "[]"
    );
    function remove(key) {
      var i = selData.findIndex((seldat) => seldat.key === key);
      if (i !== -1) {
        selData.splice(i, 1);
        localStorage.setItem("DiagnosisSelectedArray", JSON.stringify(selData));
      }
    }
    if (isSelect === true) {
      var nkey = row.key;
      var newSelectedArray = JSON.parse(
        localStorage.getItem("DiagnosisSelectedArray")
      );
      newSelectedArray.push(row);
      localStorage.setItem(
        "DiagnosisSelectedArray",
        JSON.stringify(newSelectedArray)
      );
    } else {
      remove(row.key);
    }
  };

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "Diagnosis Communication Export",
      company: "Nexion",
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  resetpage() {
    window.location.reload(false);
  }

  render() {
    const { t } = this.props;

    const columns = [
      {
        text: t("diagnosisactivations.brand"),
        dataField: "selected_vehicle.brand",
      },
      {
        text: t("diagnosisactivations.family"),
        dataField: "selected_vehicle.family",
      },
      {
        text: t("diagnosisactivations.chassis"),
        dataField: "selected_vehicle.chassis",
      },
      {
        text: t("diagnosisactivations.engine_code"),
        dataField: "selected_vehicle.engine_code_clear",
      },
      {
        text: t("diagnosisactivations.total_test"),
        dataField: "total_count",
        formatter: (rowContent, row) => {
          const total_test = row.total_count;
          return (
            <h5>
              <span className="bg-dark px-2 py-1 text-white">{total_test}</span>
            </h5>
          );
        },
      },
      {
        text: t("diagnosisactivations.success"),
        dataField: "total_count",
        formatter: (rowContent, row) => {
          const total_test = row.total_count;
          const total_failed = row.nok_count;
          return (
            <h5>
              <span className="bg-nx-success px-2 py-1 text-white">
                {total_test - total_failed}
              </span>
            </h5>
          );
        },
      },
      {
        text: t("diagnosisactivations.failed"),
        dataField: "nok_count",
        formatter: (rowContent, row) => {
          const total_failed = row.nok_count;
          return (
            <h5>
              <span className="bg-nx-danger px-2 py-1 text-white">
                {total_failed}
              </span>
            </h5>
          );
        },
      },
    ];
    const options = {
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("versionpage.firstPageText"),
      prePageText: t("versionpage.prePageText"),
      nextPageText: t("versionpage.nextPageText"),
      lastPageText: t("versionpage.lastPageText"),
      nextPageTitle: t("versionpage.nextPageTitle"),
      prePageTitle: t("versionpage.prePageTitle"),
      firstPageTitle: t("versionpage.firstPageTitle"),
      lastPageTitle: t("versionpage.lastPageTitle"),
      showTotal: true,
      showTotalTitle: "asjkas",
      totalSize: this.state.products.length,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectAll: true,
      onSelect: this.handleOnSelect,
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "Diagnosi Activations " + this.state.date + ".csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                {/* <li><button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn filter_btn waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="ti-filter"></i>
                        </button></li> */}
                {this.state.export_btn === true ? (
                  <li>
                    <Dropdown
                      isOpen={this.state.btnprimary1}
                      direction="left"
                      toggle={() =>
                        this.setState({ btnprimary1: !this.state.btnprimary1 })
                      }
                    >
                      <DropdownToggle tag="button" className="btn btn-success">
                        {t("diagnosispage.exp")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_sel")}
                              </span>
                            }
                            filename="Diagnosis Communication"
                          >
                            <ExcelSheet
                              data={JSON.parse(
                                localStorage.getItem("DiagnosisSelectedArray")
                              )}
                              name="Activations"
                            >
                              <ExcelColumn
                                label="Brand"
                                value="selected_vehicle.brand"
                              />
                              <ExcelColumn
                                label="Family"
                                value="selected_vehicle.family"
                              />
                              <ExcelColumn
                                label="Chassis"
                                value="selected_vehicle.chassis"
                              />
                              <ExcelColumn
                                label="Engine Code"
                                value="selected_vehicle.engine_code_clear"
                              />
                              <ExcelColumn
                                label="Total Test"
                                value="total_count"
                              />
                              <ExcelColumn
                                label="Succeeded"
                                value="total_count"
                              />
                              <ExcelColumn label="Failed" value="nok_count" />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_all")}
                              </span>
                            }
                            filename="Diagnosis Communication"
                          >
                            <ExcelSheet
                              data={this.state.products}
                              name="Activations"
                            >
                              <ExcelColumn
                                label="Brand"
                                value="selected_vehicle.brand"
                              />
                              <ExcelColumn
                                label="Family"
                                value="selected_vehicle.family"
                              />
                              <ExcelColumn
                                label="Chassis"
                                value="selected_vehicle.chassis"
                              />
                              <ExcelColumn
                                label="Engine Code"
                                value="selected_vehicle.engine_code_clear"
                              />
                              <ExcelColumn
                                label="Total Test"
                                value="total_count"
                              />
                              <ExcelColumn
                                label="Succeeded"
                                value="total_count"
                              />
                              <ExcelColumn label="Failed" value="nok_count" />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <br />
              <br />
              <hr />
              <div className="table-wrapper">
                {/* <div className="vertical-center"><i class="ion ion-ios-arrow-dropleft-circle left"></i><i class="ion ion-ios-arrow-dropright-circle right"></i></div> */}
                <BootstrapTable
                  striped
                  hover
                  {...props.baseProps}
                  selectRow={selectRow}
                  {...paginationTableProps}
                  pagination={paginationFactory(options)}
                  wrapperClasses="table-responsive"
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    return (
      <React.Fragment>
        {this.state.basic ? (
          <SweetAlert
            title={
              <p className="sweetalert_msg">
                No Diagnosis Communication Record found for this Vehicle
              </p>
            }
            onConfirm={() => this.setState({ basic: false })}
          ></SweetAlert>
        ) : null}

        {this.state.req_all ? (
          <SweetAlert
            title={<p className="sweetalert_msg">All fields are required</p>}
            onConfirm={() => this.setState({ req_all: false })}
          ></SweetAlert>
        ) : null}
        <MetaTags>
          <title>Diagnosis Communication | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="tabledataloader">
          <PulseLoader
            color={"#016362"}
            loading={this.state.tabledataloading}
            size={25}
          />
        </div>
        <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t("diagnosispage.get_ver_data")}
            </h5>
            <button
              type="button"
              onClick={() => this.setState({ modal_standard: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form action="#">
              <FormGroup className="mb-0 forminput">
                <Row>
                  <Col md={12}>
                    <label>{t("diagnosispage.start_date")}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                      selected={this.state.start_date}
                      selectsStart
                      id="date_pick"
                      className="date_pick form-control"
                      placeholderText="From"
                      name="start_dates"
                      value={this.state.start_date}
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleStart}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>{t("diagnosispage.end_date")}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                      selected={this.state.end_date}
                      selectsEnd
                      id="date_pick"
                      className="form-control"
                      name="end_dates"
                      value={this.state.end_date}
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleEnd}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.resetpage}
              className="btn btn-warning waves-effect"
              data-dismiss="modal"
            >
              {t("diagnosispage.reset_filter")}
            </button>
            <button
              type="button"
              onClick={this.tog_standard}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              {t("diagnosispage.cancel")}
            </button>
            <button
              type="button"
              onClick={this.getFilteredDateData}
              className="btn filter_btn waves-effect waves-light"
            >
              {t("diagnosispage.proceed")}
            </button>
          </div>
        </Modal>
        <div className="container-fluid homepageFirstRow">
          <Row>
            <Col xl={10} className="diag_table_col">
              <Card>
                <CardBody>
                  <p>
                    <b>Filter By Vehicle</b>
                  </p>
                  <AvForm onValidSubmit={this.handleValidSubmit}>
                    <Row>
                      <Col xl="2">
                        <AvField
                          type="select"
                          onChange={this.changeBrand}
                          value={this.state.brand_sel}
                          name="brand"
                          label={t("diagnosisactivations.brand")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.brands.map((item, key) => (
                            <option value={item.brand}>{item.brand}</option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="2">
                        <AvField
                          type="select"
                          onChange={this.changeFamily}
                          value={this.state.family_sel}
                          name="family"
                          label={t("diagnosisactivations.family")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.family.map((item, key) => (
                            <option value={item.family}>{item.family}</option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="4">
                        <AvField
                          type="select"
                          onChange={this.changeChassis}
                          value={this.state.chassis_sel}
                          name="chassis"
                          label={t("diagnosisactivations.chassis")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.chassis.map((item, key) => (
                            <option
                              value={
                                item.chassis +
                                "~" +
                                item.year_from +
                                "~" +
                                item.year_to
                              }
                            >
                              {item.chassis +
                                "(" +
                                item.year_from +
                                "-" +
                                item.year_to +
                                ")"}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="4">
                        <AvField
                          type="select"
                          onChange={this.changeEngine}
                          value={this.state.engine_code_sel}
                          name="engine_code"
                          label={t("diagnosisactivations.engine_code")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.engine_code.map((item, key) => (
                            <option
                              value={
                                item.variant_name +
                                "~" +
                                item.year_from +
                                "~" +
                                item.year_to +
                                "~" +
                                item.fuel +
                                "~" +
                                item.engine_code_clear +
                                "~" +
                                item.displacement +
                                "~" +
                                item.kw
                              }
                            >
                              {item.variant_name +
                                "(" +
                                item.year_from +
                                "-" +
                                item.year_to +
                                ") " +
                                item.fuel +
                                " " +
                                item.engine_code_clear +
                                " " +
                                item.displacement +
                                " " +
                                item.kw}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="2">
                        <div>
                          <Button
                            type="submit"
                            color="mm"
                            className="mr-1 uncoded_dtc_search"
                          >
                            {t("diagnosisactivations.search")}
                          </Button>{" "}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <Row>
                            <PaginationProvider
                              pagination={paginationFactory(options)}
                            >
                              {contentTable}
                            </PaginationProvider>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2} className="diag_menu_col">
            <div id="NewmySidenav">
                <Link to="/diagnosis" className=" waves-effect">
                  <i className="ion ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.diagnosis")}
                </Link>
                <Link to="/undecoded_dtc" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.undecodedDTC")}
                </Link>
                <Link to="/diagnosis_activations" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.activations")}
                </Link>
                <Link to="/diagnosis_communication" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.diagnosiscommunication")}
                </Link>
                <Link to="/diagnosis_security_gateway" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  Security Gateway Unlock
                </Link>
                <Link to="/adas_diagnosis" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  ADAS Operations
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div id="mySidenav" className="sidenav">
          <Row>
            <Col sm="3" className="icon_border">
              <i className="ion ion-ios-list" id="right_sidebar_icon"></i>
            </Col>
            <Col sm="9">
              <Link to="/diagnosis" className=" waves-effect">
                Diagnosis
              </Link>
              <Link to="/undecoded_dtc" className=" waves-effect">
                Undecoded DTC
              </Link>
              <Link to="/diagnosis_activations" className=" waves-effect">
                Activations
              </Link>
              <Link to="/diagnosis_communication" className=" waves-effect">
                Diagnosis Communication
              </Link>
            </Col>
          </Row>
        </div> */}
      </React.Fragment>
    );
  }
}

export default withTranslation()(DiagnosisCommunication);
